import { ObtenerObject} from "@/Generico/Conexion";
import ResponseCustom from '@/entidades/ResponseCustom';
import ResponseCustomObject from "@/entidades/ResponseCustomObject";
import { secondAxios } from "@/plugins/axios"
export interface DashboardMayordomoResponse {
    totalPartes: number;
    totalAnulados: number;
    totalAprobados: number;
    totalRechazados: number;
    totalSinEstado: number;
    totalPartesMes: number;
    totalAnuladosMes: number;
    totalAprobadosMes: number;
    totalRechazadosMes: number;
    totalSinEstadoMes: number;
}

export async function GetDashboardMayordomo(userId:string) : Promise<ResponseCustomObject<DashboardMayordomoResponse>>
{
    return ObtenerObject<DashboardMayordomoResponse>(`dashboardMayordomo/getDashboardByUser/${userId}`,secondAxios);
}