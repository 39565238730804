

























































































































import {Vue,Component,Prop} from 'vue-property-decorator';
import {State,Action} from 'vuex-class';
import {DashboardMayordomoResponse,GetDashboardMayordomo} from '@/entidades/Plantacion/Dashboard/DashboardMayordomo.ts';
import Loading from '@/entidades/Sistema/Loading';
var namespaced = "authModule";
@Component({
  name : 'HomeComponent'

})
export default class HomeComponent extends Vue 
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @State('rolName',{namespace:namespaced}) rolName!:string; 
    @State('usuarioId',{namespace:namespaced}) usuarioId!:string; 

    itemDash:DashboardMayordomoResponse = {
        totalPartes: 0,
  totalAnulados: 0,
  totalAprobados: 0,
  totalRechazados: 0,
  totalSinEstado: 0,
  totalPartesMes: 0,
  totalAnuladosMes: 0,
  totalAprobadosMes: 0,
  totalRechazadosMes: 0,
  totalSinEstadoMes: 0
    };
    async getInformation()
    {
        try 
        {
            this.changeLoading(new Loading(true,"Cargando informacion"));
            let response = await GetDashboardMayordomo(this.usuarioId);
            this.itemDash = response.isResult!;
        }
        catch(error:any)
        {
            console.log('error',error);
        }
        finally 
        {
            this.changeLoading(new Loading(false,""));
        }
    }


    mounted()
    {
        this.getInformation();
    }
}
